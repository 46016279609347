import { Component } from '@angular/core';
import { MenuController, ToastController, Platform, IonicModule } from '@ionic/angular';
import { Network } from '@capacitor/network';
import { ConnectionstatusService } from './services/connection-status/connectionstatus.service';
import { Device } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';
import { environment } from 'src/environments/environment';
import { LogoutComponent } from './components/logout/logout.component'
import { StorageService } from './services/storage/storage.service';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { ScanService } from './services/scan/scan.service'
import { TextStringsService } from './services/text-strings/text-strings.service';
import { LoggedInUserComponent } from './components/logged-in-user/logged-in-user.component';
import { ModalController } from '@ionic/angular';
import { NgIf } from '@angular/common';
// import { IonIcon } from "@ionic/angular/standalone";

import { addIcons } from 'ionicons';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    standalone: true,
    imports: [IonicModule, NgIf, RouterLink, LoggedInUserComponent, LogoutComponent]
})
export class AppComponent {
  
  appInfo = environment.appVersion; // default to web version from package.json

  constructor(
    public menuCtrl: MenuController, 
    private connectionstatusService: ConnectionstatusService, 
    private storage: StorageService,
    public auth: AuthService,
    public platform: Platform, 
    public router: Router,
    private scanService: ScanService,
    private modalCtrl: ModalController,
    private toastController: ToastController,
    public txt: TextStringsService
    ) {

    this.platform.ready().then(() => {
      console.log('platform ready---')
      this.menuCtrl.enable(true, 'main-more-menu');
      // setTimeout(() => {
      SplashScreen.hide()
      // }, 2000);

    });

    addIcons({
      'overview': 'assets/icons/custom-home.svg',
      'scan': 'assets/icons/custom-scan.svg',
      'gear': 'assets/icons/custom-settings.svg',
      'truck': 'assets/icons/custom-truck.svg',
      'order': 'assets/icons/custom-basket.svg',
      'history': 'assets/icons/custom-history.svg',
      'contact': 'assets/icons/custom-contact.svg',
      'help': 'assets/icons/custom-help.svg',
      'account': 'assets/icons/custom-account.svg',
      'legal': 'assets/icons/custom-legal.svg',
    });

}

  async ngOnInit() {    

    // await SplashScreen.show({
    //   showDuration: 2000,
    //   autoHide: true,
    // });
    // console.log('enabling menu from nginit')
    // this.menuCtrl.enable(true, 'main-more-menu');
    console.log(this.router.url)
    // listen to network status, and set val to service
    Network.addListener('networkStatusChange', status => {
      // console.log('Network status changed', status);
      this.connectionstatusService.connected = status?.connected

      // if changing to connected, check for offline scans, and save them BEG
      if (status?.connected) {
        this.storage.get('offLineScans').then((res: any) => {
          // console.log('found offline scans, save them', JSON.parse(res))
          this.saveOfflineScans(JSON.parse(res))
        })
        .catch((error: any) => {
          console.log('err getting saveOfflineItems', error)
          return error;
        })
      }
      // if changing to connected, check for offline scans, and save them END

    });
    // await this.storage.create();
    this.logDeviceInfo();
    console.log(this.router)
  }

  public async saveOfflineScans(scans: Array<any>) {
    Promise.allSettled( scans.map((code) => this.scanService.sendScan(code)) ).
      then((results) => {
        // present toast
        // console.log('getting this result from saveoffline scans when internet comes back', results)
        const countSucceses = results.filter((obj) => obj.status == 'fulfilled').length;
        const countFailures = results.filter((obj) => obj.status == 'rejected').length;
        this.showToast('Der var '+countSucceses+' der blev sendt ind\nDer var '+countFailures+' der fejlede')
        this.storage.delete('offLineScans')
      });
  }
  async showToast(message: string) {
    
    const toast = await this.toastController.create({
      message: 'Vi har gemt nogle scans du havde lavet mens du var offline \n'+message,
      header: 'Gemt offline items',
      duration: 5000,
      cssClass: 'toastMultiLine',
      position: 'top',
    });
    await toast.present()
  }
  // get device uuid
  public logDeviceInfo = async () => {
    const deviceId = await Device.getId();
    // put in persitant storage
    // console.log('now setting device id');
    // console.log(deviceId?.identifier);
    // alert(deviceId?.identifier);
    this.storage.set('deviceId', deviceId?.identifier);
    this.auth.deviceId = deviceId?.identifier;
  };

  toggleSideMenu() { 
    // console.log('toggle menu')
    this.menuCtrl.toggle('main-more-menu'); 
    // console.log('menu isOpen', this.menuCtrl.isOpen())
  }
  public onMenuOpen() { 
    // console.log('menu WILL open')
    //.barcode-scanning-modal
    document.querySelector('.barcode-scanning-modal')?.classList.add('lowerZindex')
    // console.log('menu isOpen', this.menuCtrl.isOpen())
  }
  public menuClosed() { 
    // console.log('menu DID close')
    document.querySelector('.barcode-scanning-modal')?.classList.remove('lowerZindex')
    // console.log('menu isOpen', this.menuCtrl.isOpen())
  }
  async openProfileModal () {
    this.toggleSideMenu()
    const modal = await this.modalCtrl.create({
      component: LoggedInUserComponent,
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      // this.message = `Hello, ${data}!`;
    }
  }
}
