import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConnectionstatusService {

  constructor() { }

  private _connected: boolean = true;

  get connected() {
    return this._connected;
  }

  set connected(connected: boolean) {
    console.log('now setting connected in service to:', connected)
    this._connected = connected;
  }
}
