<!-- <ion-modal trigger="open-modal-users"> -->
  <!-- <ng-template> -->
    <ion-header>
      <!-- <ion-toolbar> -->
        <!-- <ion-buttons slot="end">
          <ion-icon name="close" (click)="cancel()"></ion-icon>
        </ion-buttons>         -->
        <!-- <ion-buttons slot="end">
          <ion-button (click)="confirm()" [strong]="true">Confirm</ion-button>
        </ion-buttons> -->
      <!-- </ion-toolbar> -->
    </ion-header>
    <ion-content class="ion-padding">
      <!-- <ion-item> -->
        
        <!-- <div *ngFor="let company of customerProfileService.allowedCompanies; index as i; first as isFirst; last as isLast" [attr.lines]="isLast ? 'none' : ''">
          <br>
        </div> -->

        <ion-grid>
          <ion-row>
            <ion-col class="ion-text-end top-pad-modal-close">
              <!-- <ion-buttons slot="end"> -->
                <ion-icon name="close" (click)="cancel()" class="cursor"></ion-icon>
              <!-- </ion-buttons> -->
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid>
          <ion-row class="lines-bottom">
            <ion-col>
              <span class="semi-big-txt">Logget ind som</span>
            </ion-col>
            <ion-col></ion-col>
          </ion-row>
          <ion-row *ngFor="let company of customerProfileService.allowedCompanies; index as i; first as isFirst; last as isLast" class="lines-bottom cursor">
            <ion-col>
              <span class="tick-blue" [ngClass]="{'tick-blue-hide':  company.companyId!==getCurrentCustomerNumber}"></span>
            </ion-col>
            <ion-col size="9" (click)="switchUser(company.companyId)">
              <span class="body">{{company.name}}</span><br>
              <strong class="body-bold">{{company.companyId}}</strong>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="!customerProfileService.allowedCompanies.length" class="lines-bottom cursor">
            <ion-col>
              <ion-skeleton-text [animated]="true" style="width: 24px;height:24px;"></ion-skeleton-text>
            </ion-col>
            <ion-col size="9">
              <ion-skeleton-text [animated]="true" style="width: 150px;height:20px;"></ion-skeleton-text><br>
              <ion-skeleton-text [animated]="true" style="width: 150px;height:20px;"></ion-skeleton-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
            </ion-col>
            <ion-col class="ion-text-end">
              <app-logout (click)="cancel()"></app-logout>
            </ion-col>
          </ion-row>
        </ion-grid>
      <!-- </ion-item> -->
    </ion-content>
  <!-- </ng-template> -->
<!-- </ion-modal> -->