import { Injectable } from '@angular/core';
import axios from 'axios';
import { ApiUrlService } from '../api-url/api-url.service';

@Injectable({
  providedIn: 'root'
})
export class ScanService {

  constructor(private apiUrlService: ApiUrlService) {}



  async sendScan(code: any): Promise<void> {
    // console.log('code is', code)    
    let data = { LoadCarrierId: code.split('=').pop(), ScanAction: 'READY' };
    return axios.post(`${this.apiUrlService.vUrl}customer/vorespant/scan`, data);
    // .then(scan => {
    //   console.log('scan is', scan)
    //   return scan;
    // })
    // .catch(err => {
    //     return err;
    // });
  };
  // TODO cancel scan KALD FRA pickup page
  async cancelScan(code: any): Promise<void> {
    // console.log('code is', code)    
    let data = { LoadCarrierId: code, ScanAction: 'CANCELLED' };
    return axios.post(`${this.apiUrlService.vUrl}customer/vorespant/scan`, data);
    // .then(scan => {
    //   console.log('scan is', scan)
    //   return scan;
    // })
    // .catch(err => {
    //     return err;
    // });
  };
}
