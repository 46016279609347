import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { LoadingService } from '../loading/loading.service';
import { TextStringsService } from '../text-strings/text-strings.service';
import { Router } from '@angular/router';
import { ApiUrlService } from '../api-url/api-url.service';
// import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  // OLD temp solution here set a service var, e.g. isAuthenticated = something something e.g. res.data.CorrelationId from storage
  // also set res.data.CorrelationId storage
  private _isAuthenticated: boolean = false; // TODO need some "initial" concept, eg loading until hasPersistedLogin is done
  private _isAdmin: boolean = false; // TODO need some "initial" concept, eg loading until hasPersistedLogin is done

  private _accessToken: string = '';
  private _refreshToken: string = '';
  private _deviceId: string = '';
  private _drsId: string = '';
  private _correlationId: string = '';
  private _headers: object = {};
  private _customerNumber: number = 0;
  // TODO maybe add customernumber here

  
  // TODO above maybe a FCM token in same flow?

  // TODO here vars for all the potential values in async storage, then set them correctly in promise All run by hasPersistedLogin() at load
  // so they can be easily grabbed like local service vars by registrer()

  // coming solution this would be question of looking for a JWT (from storage) and maybe check an api if it's still valid (also at some interval)
  // if no JWT present, go thru auth flow with customer no 

  constructor(public storage: StorageService, private router: Router, private loadingService: LoadingService, private apiUrlService: ApiUrlService, private textService: TextStringsService) {
    // this.deviceId = await storage.get('name');
    console.log('running auth service constructor')
    // TODO maybe set a global loader true here
    this.hasPersistedLogin()
    
  }

  get isAdmin() {
    return this._isAdmin;
  }
  get isAuthenticated() {
    return this._isAuthenticated;
  }
  get customerNumber() {
    return this._customerNumber;
  }
  // set isAuthenticated(isAuthenticated: boolean) {
  //   console.log('now setting isAuthenticated in service to:', isAuthenticated)
  //   this._isAuthenticated = isAuthenticated;
  // }

  set deviceId(deviceId: string) {
    console.log('now setting deviceId in auth service to:', deviceId)
    this._deviceId = deviceId;
  }

  public setLogOut() {
      this.loadingService.loadingPresent();
      this.storage.clear().then(res => {
      console.log('logout auth nuke async storage complete ...', res);

      // nuke interceptors
      axios.interceptors.request.clear()
      axios.interceptors.response.clear()
      console.log('logout nuke interceptors complete ...');
      
      this._isAuthenticated = false;
      this.loadingService.loadingDismiss();
      this.router.navigate(['/']);
    })
    .catch(error => {
      this.loadingService.loadingDismiss();  
      return error;
    }); 
  }
  public loggedInInterceptor () {
    console.log('called loggedInInterceptor...')
    // kill old ones first?
    axios.interceptors.request.clear()
    axios.interceptors.response.clear()
    
    axios.interceptors.response.use(
      response => response,
      async error => {
        if (error.response.status === 401) {
          console.log('got 401 need to refresh tokens....')
          console.log('complete error', JSON.stringify(error))
          delete axios.defaults.headers.common['Authorization']
          const data = await this.oAuthRefreshToken();
          if (data !== 'fail') {
            console.log('data new tokens', data)
            console.log('accessToken for st', data['accessToken'])
            console.log('refreshToken for st', data['refreshToken'])
            this.storage.set('accessToken', data['accessToken'])
            this.storage.set('refreshToken', data['refreshToken'])
            // axios.defaults.headers.common = {                      
            //   'Authorization': `Bearer ${data['accessToken']}`,
            // };
            
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data['accessToken']
            error.config.headers['Authorization'] = 'Bearer ' + data['accessToken']
            console.log('axios.defaults.headers.common Authorization from interceptor', axios.defaults.headers.common['Authorization'])
            console.log('set them new ones in storage & refresh global axios header values')

            // localStorage.setItem('authToken', newToken);
            // Retry the original request
            console.log('err.config: ', JSON.stringify(error.config))
            return axios(error.config);
          }
          else {
            // TODO logout
            this.setLogOut()
          }
          
        }
        return Promise.reject(error);
      }
    );
  }
  // public async onOAuthRefreshBtnClick() {
  public async oAuthRefreshToken() {
    // if (!this.refreshToken) {
    //   console.error('No refresh token found. Log in with OAuth first.');
    // }
    return new Promise((resolve, reject) => {
      this.storage.get('refreshToken').then((res: any) => {
          // res
          // /* TODO ONLY APPS??? BEG
          // const oauth2RefreshOptions = { ...this.oauth2Options, refreshToken: res };
          // // oauth2RefreshOptions.refreshToken = this.refreshToken
          // OAuth2Client.refreshToken(
          //   oauth2RefreshOptions
          // ).then(response => {
          //   const accessToken = response['access_token'];
          //   // Don't forget to store the new refresh token as well!
          //   const refreshToken = response['refresh_token'];
          //   console.log('refreshed accessToken', accessToken)
          //   console.log('refreshed refreshToken', refreshToken)
          //   resolve({accessToken, refreshToken})
          //   // Go to backend
          // }).catch(reason => {
          //   console.error('Refreshing token failed', reason);
          //   reject('fail')
          // });
          // /* TODO ONLY APPS??? END
          // oauth2Options = {
          //   appId: '41938d77-9b7d-42fb-a038-24ff333d5476', // client_id // OLD: 41938d77-9b7d-42fb-a038-24ff333d5476, NEW: 41938d77-9b7d-42fb-a038-24ff333d5476
          //   // authorizationBaseUrl: 'https://integrationitb2c2.b2clogin.com/tfp/integrationitb2c2.onmicrosoft.com/B2C_1_policy-signin-signup-web/oauth2/v2.0/authorize',
          //   //    authorizationBaseUrl: 'https://b2cdrstest.b2clogin.com/drsident.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_VoresPantSignIn',
          //   //    accessTokenEndpoint: 'https://b2cdrstest.b2clogin.com/drsident.onmicrosoft.com/oauth2/v2.0/token?p=B2C_1A_VoresPantSignIn',
          //   authorizationBaseUrl: 'https://b2cdrstest.b2clogin.com/drsident.onmicrosoft.com/B2C_1A_VoresPantSignIn/oauth2/v2.0/authorize',
          //   accessTokenEndpoint: 'https://b2cdrstest.b2clogin.com/drsident.onmicrosoft.com/B2C_1A_VoresPantSignIn/oauth2/v2.0/token',
          // let data = {
          //   grant_type: 'refresh_token',
          //   client_id: this.oauth2Options.appId,
          //   refresh_token: res,
          // }
          const formData = new FormData();
          formData.append("grant_type", "refresh_token");
          formData.append("client_id", environment.oAuthAppId);
          formData.append("refresh_token", res);
          axios.post(environment.oAuthTokenEndpoint, formData).then((response:any) => {
            console.log('refresh details', response?.data)
            const accessToken = response?.data['access_token'];
            const refreshToken = response?.data['refresh_token'];
            console.log('new accessToken', accessToken)
            console.log('new refreshToken', refreshToken)
            resolve({accessToken, refreshToken})
            return response;
          })
          .catch(err => {
              this.setLogOut()
              reject('fail')
              return err;
          });


      })
      .catch((error: any) => {
        console.log('err getting refreshToken', error)
        reject('fail')
        return error;
      })
      

    });
  }
  hasPersistedLogin () {
    this.loadingService.loadingPresent()
    this.loggedInInterceptor()
    // TODO promise all get persistant storage here, assign to service local vars
    // TODO later this will probably as an additional step check (recurring?) a JWT is present AND still valid
    if (this._accessToken) {
      // TODO maybe set a global loader false here
      console.log('persisted login already found local service var - this is an active session without "reload"')
      this._isAuthenticated = true;
      // TODO this needs to do some token refresh stuff
      this.setGlobalAuthHeaders();
    }
    else {
      // Promise all
      // this.storage.get('accessToken').then((res: any) => {
        Promise.all([this.storage.get('accessToken'), this.storage.get('deviceId'), this.storage.get('drsId'), this.storage.get('correlationId')]).then(values => {
        
        // TODO later this will probably as an additional step check (recurring?) a JWT is present AND still valid, probably here abouts
        // TODO maybe set a global loader false here
        console.log('values', values)
        console.log('values 0', values[0])
        console.log('values 1', values[1])
        console.log('values 2', values[2])
        if (values[0] && values[2]) {
          console.log('persisted login FOUND in async storage, loading dismiss, set global auth headers, nav to home')
          this._accessToken = values[0]
          this._deviceId = values[1] || this._deviceId
          this._drsId = values[2]
          this._isAuthenticated = true;
          this._correlationId = values[3]
          // router send to home

          this.setGlobalAuthHeaders().then(data => {
            console.log('promise from setGlobalAuthHeaders succ, naving to home', data)
            this.router.navigate(['home']);
          })
          .catch(error => {
              // setLoading(false);
              console.log('promise from setGlobalAuthHeaders err')
              let errMsg = error?.data?.ERROR ? error?.data?.ERROR : 'Fejl i kundenummer - prøv igen!'
              console.log(errMsg)
              // this.presentToast('middle', errMsg, 4000)
          })
          .finally(() => this.loadingService.loadingDismiss());

          
          // this.setGlobalAuthHeaders();
          
        }
        else {
          console.log('NO persisted login from async storage either, loader dismiss and set registrer headers for likely registrer() call')
          this._isAuthenticated = false;
          this.loadingService.loadingDismiss();
          this.setRegistrerHeaders();
          this.router.navigate(['/']);
        }
        
        
      })
      .catch(error => {
        // TODO maybe set a global loader false here
        console.log('error getting  async storage, loader dismiss and set registrer headers for likely registrer() call')
        this._isAuthenticated = false;
        this.loadingService.loadingDismiss();
        this.setRegistrerHeaders();
        console.log('err', error)
        // return false;
      }); 
    }
  }
  async setGlobalAuthHeaders () {
    // TODO this needs to be a async promise func, and all callers should await resp before for instance naving to Home
    this.textService.fetchTexts()
    console.log('setting setGlobalAuthHeaders')
    // axios.defaults.headers.common = {
    //   ...this._headers,
    //   CorrelationId: this._correlationId,
    //   DrsId: this._drsId,
    //   DeviceId: this._deviceId,
    // };
    return new Promise((resolve, reject) => {
      this.storage.get('accessToken').then((res: any) => {
        console.log('DrsId in setGlobalAuthHeaders',this._drsId)
        console.log('accessToken in setGlobalAuthHeaders',res)
        // axios.defaults.headers.common = {'Authorization': `Bearer ${res}`} 
        axios.defaults.headers.common = {
          ...this._headers,
          CorrelationId: this._correlationId,
          DrsId: this._drsId,
          DeviceId: this._deviceId,
          'Authorization': `Bearer ${res}`,
        };
        console.log('axios.defaults.headers.common',axios.defaults.headers.common)
        resolve(res);
        return res;

      })
      .catch((error: any) => {
        console.log('err getting cno', error)
        reject('der skete en fejl');
        return error;
      })
    });
  }
  setRegistrerHeaders () {
    console.log('setting setRegistrerHeaders')
    // const correlationId = crypto.randomUUID(); // TODO likely move up top to service local vars
    const correlationId = crypto.randomUUID(); // TODO likely move up top to service local vars
    this._headers = {
      // 'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Method': 'GET, HEAD, POST, PATCH, OPTIONS',
      'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, CorrelationId, DeviceId, Access-Control-Request-Method, Access-Control-Allow-Origin',
      // Connection: 'keep-alive',
      'Accept': 'application/json',
      CorrelationId: correlationId,
      DeviceId: this._deviceId,
    };
    
  }

  async registrer(accessToken: string, customerNo: number, refreshToken: string) {
    this.loadingService.loadingPresent()
    console.log('setting token', accessToken)
    this.storage.set('accessToken', accessToken)
    this.storage.set('refreshToken', refreshToken)
    // main promise BEG
    return new Promise((resolve, reject) => {
     
        let headers = {
          ...this._headers,          
          'Authorization': `Bearer ${accessToken}`,
        }
        const data = {
          ProfileNo: customerNo, // 5790000020955
          DeviceId: this._deviceId,
        };    
        
        // orginal auth BEG
        axios
        .post(`${this.apiUrlService.vUrl}customer/vorespant/registration/`, data, {
            headers,
        })
        .then(res => {
          console.log('res from auth: ', res)
          if (!res?.data?.DrsUniqueIdentification) {
            reject('der skete en fejl');
          }
          this._correlationId = res?.data?.CorrelationId;
          this._drsId = res?.data?.DrsUniqueIdentification;        
          this._accessToken = accessToken 
          this._refreshToken = refreshToken 
          // PERSIST LOGIN 
          
          this.storage.set('drsId', res?.data?.DrsUniqueIdentification)
          this.storage.set('correlationId', res?.data?.CorrelationId)
          this.storage.set('customerNumber', customerNo)
          // this.setGlobalAuthHeaders();
          // this._isAuthenticated = true;
          // this.loadingService.loadingDismiss();
          this._isAuthenticated = true
          // sessionStorage.setItem('b2c.access.token', response?.accessToken)
          
          //this.setGlobalAuthHeaders()
          //this.loadingService.loadingDismiss()
          

          this.setGlobalAuthHeaders().then(data => {
            // console.log('promise from setGlobalAuthHeaders succ, naving to home', data)
            // this.router.navigate(['home']);
            resolve(res.data)
            return res.data
          })
          .catch(error => {
              // setLoading(false);
              // console.log('promise from setGlobalAuthHeaders err')
              // let errMsg = error?.data?.ERROR ? error?.data?.ERROR : 'Fejl i kundenummer - prøv igen!'
              console.log(error)
              // this.presentToast('middle', errMsg, 4000)
              reject('der skete en fejl fra registrer-->globalauthheaders kaldet')
              return res.data
          })
          .finally(() => this.loadingService.loadingDismiss());



        })
        .catch(error => {
            let errMsg = error?.response?.data?.ERROR ? error?.response?.data?.ERROR : 'Der opstod en ukendt fejl'
            if (error?.response?.status === 500) {
                console.log('error:', error.response)
                this._isAuthenticated = false;
                this.loadingService.loadingDismiss();
                reject('errorno allowed'+error.response);
                // Alert.alert('Hov...', errMsg); // TODO this is wrong needs title & text, otherwise 1 param is title, which is chopped
            }
            return error;
        });
        // orginal auth END
      
    });
    // // main promise END



    
  }

  

}
