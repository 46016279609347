import { Injectable } from '@angular/core';
import axios from 'axios';
import { StorageService } from '../storage/storage.service';
import { ApiUrlService } from '../api-url/api-url.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private _bookings: Array<any> = [];
  private _history: Array<any> = [];

  constructor(private storage: StorageService, private apiUrlService: ApiUrlService) {
    
    // this.storage.get('customerNumber').then((res: any) => {
    //   this.fetchBookings(res);
    // })
    // .catch((error: any) => {
    //   console.log('err getting cno', error);  
    //   return error;
    // }); 
  }

  get bookings() {
    return this._bookings
  }
  get history() {
    return this._history
  }

  async fetchBookings(customerNumber: any): Promise<void> {
    // console.log('fetchBookings cno is', customerNumber)    
    let rawval = []
    return await axios.get(`${this.apiUrlService.vUrl}customer/vorespant/booking/${customerNumber}`).then(bookings => {
      // TODO også vise "historik" "collectionList"
      // TODO under bookingList kig efter "weekFrequency" && "newBookingDay"
      // console.log('bookings fetchBookings', bookings.data)
      // console.log('bookings fetchBookings null?', bookings?.data?.bookingList==null)
      
      rawval = typeof bookings?.data?.bookingList!=='object' || bookings?.data?.bookingList==null ? ['noop'] : bookings?.data?.bookingList;
      // console.log('rawval typeof', typeof rawval)
      // console.log('rawval', rawval)
      if (rawval[0]=='noop') {
        this._bookings = rawval
        // console.log('rawval', rawval)
        if (bookings?.data?.vendorInfo?.collectedBy.length>0) {
          this._bookings = ['coop']
          // console.log('this._bookings', this._bookings)
        }
        // console.log('booking data UNfiltered', raw)
        // console.log('booking data raw', this._bookings)
        
        return bookings?.data;
      }
      else {
        let availableBookings = rawval.reduce((acc, book) => {
          if (book?.bookingType!=='Bookingtilbud') {
              acc.push(book);
          } 
          return acc;
        }, []);
        if (availableBookings.length==0) {
          availableBookings.push('noop')
        }        
        this._bookings = availableBookings
        // console.log('booking data UNfiltered', raw)
        console.log('booking data filtered', this._bookings)
        return bookings?.data;
      }
      
    })
    .catch(err => {
        return err;
    });
  };
  async fetchHistory(customerNumber: any): Promise<void> {
    console.log('cno is', customerNumber)    
    return await axios.get(`${this.apiUrlService.vUrl}customer/vorespant/booking/${customerNumber}`).then(history => {
      // TODO også vise "historik" "collectionList"
      // TODO under bookingList kig efter "weekFrequency" && "newBookingDay"
      // console.log('bookings', bookings.data)
      this._history = !history?.data?.collectionList.length ? ['noop'] : history?.data?.collectionList;
      console.log('history data', this._history);
      
      return history?.data;
    })
    .catch(err => {
        return err;
    });
  };
}