import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class ApiUrlService {
  
  private _apiUrl: string = '/api/'
  private _cmsUrl: string = '/cms/'
  private _vUrl: string = '/vpant/'

  constructor(private Platform: Platform) {}

  get apiUrl() {
    if (this.Platform.is('capacitor')) {
      this._apiUrl = environment.apiUrl+'/';
    }
    return this._apiUrl;
  }
  get vUrl() {
    if (this.Platform.is('capacitor')) {
      this._vUrl = environment.vUrl+'/';
    }
    return this._vUrl;
  }
  get cmsUrl() {
    if (this.Platform.is('capacitor')) {
      this._cmsUrl = environment.cmsUrl+'/';
    }
    return this._cmsUrl;
  }

}
