import { Injectable } from '@angular/core';
import axios from 'axios';
import { ApiUrlService } from '../api-url/api-url.service';
import { StorageService } from '../../services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerProfileService {

  private _companyName: string = '';
  private _companyNumber: string = '';
  private _companies: Array<any> = [];

  constructor(private apiUrlService: ApiUrlService, private storage: StorageService,) {
    if(!this._companyName) {
      console.log('gon init a profile call')
      this.getCustomerProfile()
    }    
  }
  get companyNumber() {
    return this._companyNumber;
  }
  get companyName() {
    return this._companyName;
  }
  get allowedCompanies() {
    return this._companies;
  }

  async getCustomerProfile(): Promise<void> {
    // console.log('axios defaults', axios.defaults.headers.common)
    this.storage.get('customerNumber').then((res: any) => {
      console.log('getCustomerProfile set companyNo', res)
      this._companyNumber = res
    })
    .catch((error: any) => {
      console.log('err getting cno', error)
      return error;
    })
    return await axios.get(`${this.apiUrlService.vUrl}customer/vorespant/profile`).then(customerResult => {
      console.log('got profile', customerResult?.data?.Profile)
      console.log('companyName is', customerResult?.data?.Profile?.Name)
      this._companyName = customerResult?.data?.Profile?.Name
      return customerResult?.data;
    })
    .catch(err => {
        return err;
    });
  };
  async getAllowedCompanies(): Promise<void> {
    console.log('axios defaults', axios.defaults.headers.common)
    return await axios.get(`${this.apiUrlService.vUrl}customer/administration/companies/allowed`).then(companies => {
      console.log('got companies', companies?.data)
      this._companies = companies?.data
      return companies?.data;
    })
    .catch(err => {
        return err;
    });
  };
  
  async patchCustomerProfile(data: Object): Promise<void> {
    console.log('axios defaults', axios.defaults.headers.common)
    //return axios.patch(url, data);
    return await axios.patch(`${this.apiUrlService.vUrl}customer/vorespant/profile`, data).then(customerResult => {
      console.log('customerResult from patch', customerResult)
      return customerResult;
    })
    .catch(err => {
        return err;
    });
  };
  
  async emailCustomerService(data: any): Promise<void> {
    console.log('axios defaults', axios.defaults.headers.common)
    let headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*/*',
      Connection: 'keep-alive',
      Accept: 'application/json',
      // Authorization: 'ck_d96a6d0cc74df6be3e8ea94a20eb28e5b699a143:cs_959cb5a425b7306f6221e03ab2f1d76e060a3d6a',
    };
    console.log('url', this.apiUrlService.cmsUrl+'wp-json/gf/v2/entries')
    return new Promise((resolve, reject) => {
    axios
    .post(this.apiUrlService.cmsUrl+'wp-json/gf/v2/entries', data, { //'https://danskretursystem.dk/wp-json/gf/v2/entries'
      headers,
      auth: {
          username: 'ck_d96a6d0cc74df6be3e8ea94a20eb28e5b699a143',
          password: 'cs_959cb5a425b7306f6221e03ab2f1d76e060a3d6a',
        },
      })
    .then(res => {
      console.log('emailCustomerService data res', res)
      resolve(res.data); 
      return res;
    })
    .catch(err => {
        console.log('err', err)
        reject(err); 
        return err;
    });
  });

  };



}
