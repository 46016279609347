import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ToastController } from '@ionic/angular';
import { AuthService } from '../../services/auth/auth.service';
import { LoadingService } from '../../services/loading/loading.service';
import { StorageService } from '../../services/storage/storage.service';
import { BookingService } from '../../services/booking/booking.service';
import { RegistrationsService } from '../../services/registrations/registrations.service';
import { FormsModule } from '@angular/forms';
import { LogoutComponent } from '../logout/logout.component'
// import { Router } from '@angular/router';
import { CustomerProfileService } from '../../services/customer-profile/customer-profile.service';

@Component({
  selector: 'app-logged-in-user',
  templateUrl: './logged-in-user.component.html',
  styleUrls: ['./logged-in-user.component.scss'],
  imports: [CommonModule, IonicModule, FormsModule, LogoutComponent],
  standalone: true,
})
export class LoggedInUserComponent  implements OnInit {
  name: string;
  _currentUserId: number;
  constructor(
    private modalCtrl: ModalController,
    public customerProfileService: CustomerProfileService,
    private storage: StorageService,
    public loadingService: LoadingService, 
    private authService: AuthService, 
    // private router: Router, 
    public bookingService: BookingService, 
    public registrationsService: RegistrationsService,
    private toastController: ToastController,
  ) { }

  get getCurrentCustomerNumber () {
    return this._currentUserId
  }

  ionViewDidEnter() {
    console.log('loggedinmodal ionViewDidEnter')
    this.storage.get('customerNumber').then((res: any) => {
      this._currentUserId = res
    })
    .catch((error: any) => {
      console.log('err getting cno', error)
      return error;
    })
    this.customerProfileService.getAllowedCompanies()
  }
  async presentToast(position: 'top' | 'middle' | 'bottom', msg: string, duration: number) {
    const toast = await this.toastController.create({
      message: msg,
      duration: duration,
      position: position,
      icon: 'warning'
    });

    await toast.present();
  }
  switchUser (userid: any) {
    // this.loadingService.loadingPresent()

    Promise.all([this.storage.get('accessToken'), this.storage.get('refreshToken')]).then(values => {
      this.authService.registrer(values[0], userid, values[1]).then(data => {
        // console.log('promise from login.page.ts succ, naving to home', data)
        // TODO call/set up axios interceptor BEG
        //  this.authService.loggedInInterceptor()
        // TODO call/set up axios interceptor END
        // this.loadingService.loadingDismiss()
        // TODO reload all services
        // profile
        this.customerProfileService.getCustomerProfile()
        // registrations
        this.registrationsService.fetchRegistrations()
        this.bookingService.fetchHistory(userid)
        this.bookingService.fetchBookings(userid)
        // bookings
        this.cancel()
        // this.router.navigate(['home'])
        })
        .catch(error => {
            // setLoading(false);
            console.log('promise from login.page.ts err')
            // this.loadingService.loadingDismiss()
            let errMsg = error?.data?.ERROR ? error?.data?.ERROR : 'Fejl i kundenummer - prøv igen!'
            console.log(errMsg)
            this.presentToast('middle', errMsg, 4000)
        });
    })
    .catch(error => {
      
      
    }); 
    
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel')
  }

  confirm() {
    return this.modalCtrl.dismiss(this.name, 'confirm')
  }

  ngOnInit() {}

}
