<ion-app>
<div class="gridContainer">
  <ion-grid class="mainGrid">
    <ion-row class="gridRow">
      <ion-col class="d-side-menu" size="auto" *ngIf="platform.is('desktop') && router.url !== '/'">
        <div style="width: 240px" [routerLink]="['/home']">
          <div class="logo"></div>
        </div>
        <div class="ion-padding-start ion-padding-top">
          <ion-chip [routerLink]="['/home']">
            <ion-icon name="overview" color="secondary"></ion-icon>
            <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_HOME') || 'Hjem'}}</ion-label>
          </ion-chip>
        </div>
        <div class="ion-padding-start ion-padding-top">
          <ion-chip [routerLink]="['/scan']">
            <ion-icon name="scan" color="secondary"></ion-icon>
            <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_SCAN') || 'Scan'}}</ion-label>
          </ion-chip>
        </div>
        <div class="ion-padding-start ion-padding-top">
          <ion-chip [routerLink]="['/pickup']">
            <!-- <ion-icon name="bus" color="secondary"></ion-icon> -->
            <ion-icon name="truck" color="secondary"></ion-icon>
            <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_PICKUP') || 'Afhentning'}}</ion-label>
          </ion-chip>
        </div>
        <div class="ion-padding-start ion-padding-top menu-divider">
          <ion-chip [routerLink]="['/equipment']">
            <ion-icon name="gear" color="secondary"></ion-icon>
            <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_EQUIPMENT') || 'Anlæg'}}</ion-label>
          </ion-chip>
        </div>
        
        <!-- divider / more -->

        <div class="ion-padding-start ion-padding-top" *ngIf="auth.isAdmin">
            

            <details>
              <summary>
<!--  btn stuff -->
            <ion-icon name="people" color="secondary"></ion-icon>
            <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_MORE_USERS') || 'Brugere'}}</ion-label>
<!--  btn stuff -->
                <div class="summary-chevron-up">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                </div>
              </summary>

<!-- extra menu items -->
<div class="menu-sub-item">
  <ion-label class="menu-label" color="secondary" [routerLink]="['/users']">&nbsp;{{ txt.getText('MENU_ITEM_MORE_USERS') || 'Overblik'}}</ion-label>
</div>
<div class="menu-sub-item">
  <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_MORE_USERS_ADD') || 'Opret brugere'}}</ion-label>
</div>
<!-- extra menu items -->

              <div class="summary-chevron-down">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg>
              </div>
            </details>




        </div>

        <div class="ion-padding-start ion-padding-top">
          <ion-chip [routerLink]="['/order']">
            <ion-icon name="order" color="secondary"></ion-icon>
            <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_MORE_ORDER') || 'Bestil'}}</ion-label>
          </ion-chip>
        </div>
        <div class="ion-padding-start ion-padding-top">
          <ion-chip [routerLink]="['/history']">
            <ion-icon name="history" color="secondary"></ion-icon>
            <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_MORE_HISTORY') || 'Historik'}}</ion-label>
          </ion-chip>
        </div>
        <div class="ion-padding-start ion-padding-top">
          <ion-chip [routerLink]="['/contact']">
            <ion-icon name="contact" color="secondary"></ion-icon>
            <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_MORE_CONTACT') || 'Kontakt'}}</ion-label>
          </ion-chip>
        </div>
        <div class="ion-padding-start ion-padding-top">
          <ion-chip [routerLink]="['/faq']">
            <ion-icon name="help" color="secondary"></ion-icon>
            <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_MORE_FAQ') || 'Spørgsmål og svar'}}</ion-label>
          </ion-chip>
        </div>
        <div class="ion-padding-start ion-padding-top">
          <ion-chip [routerLink]="['/legal']">
            <ion-icon name="legal" color="secondary"></ion-icon>
            <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_MORE_LEGAL') || 'Vilkår'}}</ion-label>
          </ion-chip>
        </div>
        <div class="ion-padding-start ion-padding-top">
          <ion-chip (click)="openProfileModal()">
            <ion-icon name="account" color="secondary"></ion-icon>
            <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_MORE_PROFILE') || 'Profil'}}</ion-label>
          </ion-chip>
        </div>
      </ion-col>
      <ion-col>
        <ion-router-outlet id="main-more-menu-content"></ion-router-outlet>
      </ion-col>
    
    </ion-row>
  </ion-grid>
</div>

  <ion-tabs *ngIf="platform.is('mobile')">
    <ion-tab-bar slot="bottom" *ngIf="router.url !== '/'">
      <ion-tab-button tab="home">
        <ion-icon name="overview"></ion-icon>
        {{ txt.getText('MENU_ITEM_HOME') || 'Hjem'}}
      </ion-tab-button>
      <ion-tab-button tab="scan">
        <ion-icon name="scan"></ion-icon>
        {{ txt.getText('MENU_ITEM_SCAN') || 'Scan'}}
      </ion-tab-button>
      <ion-tab-button tab="pickup">
        <ion-icon name="truck"></ion-icon>
        {{ txt.getText('MENU_ITEM_PICKUP') || 'Afhentning'}}
      </ion-tab-button>
      <ion-tab-button tab="equipment">
        <ion-icon name="gear"></ion-icon>
        {{ txt.getText('MENU_ITEM_EQUIPMENT') || 'Anlæg'}}
      </ion-tab-button>
      <ion-tab-button (click)="toggleSideMenu()">
          <ion-icon name="menu-outline"></ion-icon>
          {{ txt.getText('MENU_ITEM_MORE') || 'Mere'}}
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>


  
  <ion-menu contentId="main-more-menu-content" menuId="main-more-menu" (ionWillOpen)="onMenuOpen()" (ionDidClose)="menuClosed()">
    <ion-header>
      <ion-toolbar>
        <ion-title></ion-title>
        <div class="ion-text-end">
          <ion-icon name="close-outline" (click)="toggleSideMenu()"></ion-icon>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <div class="gridContainer">
        <ion-grid class="mainGrid">
          <ion-row class="gridRow">
            <ion-col>
              <div class="ion-padding-start ion-padding-top">
                <ion-chip (click)="toggleSideMenu()" [routerLink]="['/order']">
                  <ion-icon name="order" color="secondary"></ion-icon>
                  <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_MORE_ORDER') || 'Bestil'}}</ion-label>
                </ion-chip>
              </div>
              <div class="ion-padding-start ion-padding-top">
                <ion-chip (click)="toggleSideMenu()" [routerLink]="['/history']">
                  <ion-icon name="history" color="secondary"></ion-icon>
                  <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_MORE_HISTORY') || 'Historik'}}</ion-label>
                </ion-chip>
              </div>
              <div class="ion-padding-start ion-padding-top">
                <ion-chip (click)="toggleSideMenu()" [routerLink]="['/contact']">
                  <ion-icon name="contact" color="secondary"></ion-icon>
                  <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_MORE_CONTACT') || 'Kontakt'}}</ion-label>
                </ion-chip>
              </div>
              <div class="ion-padding-start ion-padding-top">
                <ion-chip (click)="toggleSideMenu()" [routerLink]="['/faq']">
                  <ion-icon name="help" color="secondary"></ion-icon>
                  <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_MORE_FAQ') || 'Spørgsmål og svar'}}</ion-label>
                </ion-chip>
              </div>
              <div class="ion-padding-start ion-padding-top">
                <ion-chip (click)="toggleSideMenu()" [routerLink]="['/legal']">
                  <ion-icon name="legal" color="secondary"></ion-icon>
                  <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_MORE_LEGAL') || 'Vilkår'}}</ion-label>
                </ion-chip>
              </div>
              <div class="ion-padding-start ion-padding-top">
                <ion-chip (click)="openProfileModal()">
                  <ion-icon name="account" color="secondary"></ion-icon>
                  <ion-label class="menu-label" color="secondary">&nbsp;{{ txt.getText('MENU_ITEM_MORE_PROFILE') || 'Profil'}}</ion-label>
                </ion-chip>
              </div>
              <!-- <div class="ion-padding-start ion-padding-top">
                <ion-chip (click)="toggleSideMenu()">
                  <ion-icon name="log-out" color="secondary" style="padding-right:6px;"></ion-icon>
                  <ion-label class="menu-label" color="secondary"><app-logout style="color:white;"></app-logout></ion-label>
                </ion-chip>
              </div> -->
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

    </ion-content>
    <!-- BEG APP info -->
    <ion-footer class="ion-no-border ion-text-end appversion" color="secondary">
      <ion-toolbar class="appversion">
    
        <ion-grid>
          <ion-row class="ion-align-items-start">
            <!-- <ion-col *ngIf="platform.is('mobile')">
              <div class="ion-text-start">
                <app-logout></app-logout>
                <app-connection-info></app-connection-info>
              </div>
            </ion-col>
            <ion-col *ngIf="platform.is('desktop')">
              <div class="ion-text-start">
                
              </div>
            </ion-col> -->
            <ion-col class="ion-text-start">
              <!-- <span class="appVersion-txt ion-padding-end ion-text-end">app version: {{appInfo}} ({{currentEnv}})</span> -->
              <!-- <span class="appVersion-txt ion-padding-end ion-text-end">app version: {{appInfo}} (test)</span> -->
              <ion-chip (click)="toggleSideMenu()">
                <ion-icon name="log-out" color="secondary" style="padding-right:6px;"></ion-icon>
                <ion-label class="menu-label" color="secondary"><app-logout style="color:white;"></app-logout></ion-label>
              </ion-chip>
            </ion-col>
          </ion-row>
        </ion-grid>
    
        
      </ion-toolbar>
    </ion-footer>
    <!-- END APP info -->
  </ion-menu>


</ion-app>
