import { Injectable } from '@angular/core';
import axios from 'axios';
import { ApiUrlService } from '../api-url/api-url.service';

@Injectable({
  providedIn: 'root'
})



export class RegistrationsService {


  private _registrations: Array<any> = [];

  constructor(private apiUrlService: ApiUrlService) {
    
    // this.fetchRegistrations();
    
  }

  get registrations() {
    // console.log('registrations from service', this.sackSections(this._registrations))
    return this.sackSections(this._registrations)
  }

  // TODO maybe only below include scanaction ready
   sackSections(sacks:Array<any>): Array<any> {
    const sections = sacks.reduce(
        (acc, sack) => ({
            ...acc,
            [sack.ScanAction]: [...(acc[sack.ScanAction] || []), sack],
        }),
        {}
    );
    // console.log('sections', sections)
    //return sections
    return Object.keys(sections).map(key => ({
        title: this.STATUSTEXTS[key],
        subtitle: key,
        data: sections[key],
    }));
}
STATUSTEXTS = {
  READY: 'Klar til afhentning',
  COUNTED: 'Optalt',
  DRSDELEVD: 'Indsamlet',
  COLLECTED: 'Afhentet',
} 
MATERIALS = {
  BIG: 'QR01',
  SMALL: 'QR02',
  STRIPS: 'D100',
  TUB: 'P300',
  RFID_TUB: 'B028',
  RFID_STRIPS: 'B032',
};

MATERIAL_NAMES = {
  [this.MATERIALS.BIG]: 'Store sække',
  [this.MATERIALS.SMALL]: 'Små sække',
  [this.MATERIALS.TUB]: 'Foldekar',
  [this.MATERIALS.STRIPS]: 'Strips',
};
 MATERIAL_NAMES_HELPER = {
  [this.MATERIALS.BIG]: 'Til plastikflasker og dåser',
  [this.MATERIALS.SMALL]: 'Til glasflasker',
  [this.MATERIALS.TUB]: 'Til glasflasker',
  [this.MATERIALS.STRIPS]: 'Strips',
};
CARRIER = {
  EXPRESS: {
      [this.MATERIALS.BIG]: [5, 10],
      [this.MATERIALS.SMALL]: [5, 10],
      [this.MATERIALS.STRIPS]: [1, 2],
  },
  REGULAR: {
      [this.MATERIALS.BIG]: [5, 10, 15, 20, 25, 30, 35, 260],
      [this.MATERIALS.SMALL]: [5, 10, 15, 20, 25, 30, 35, 800],
      [this.MATERIALS.STRIPS]: [1, 2],
  },
};
  public containerNumbertoName (key: any) {
    return `${this.MATERIAL_NAMES[key]} - ${this.MATERIAL_NAMES_HELPER[key]}`
  }
  async fetchRegistrations(): Promise<void> {
    console.log('is this not run again.. fetchRegistrations')
    return await axios.get(`${this.apiUrlService.vUrl}customer/vorespant/registrations`).then(registrations => {
      // console.log('registrations', registrations)
      this._registrations = !registrations?.data?.LoadCarrierRegistrationList?.LoadCarrierRegistration.length || registrations?.data?.LoadCarrierRegistrationList==null ? ['noop'] : registrations?.data?.LoadCarrierRegistrationList?.LoadCarrierRegistration;
      console.log('registrations data', this._registrations);

      console.log('sectioned sacks', this.sackSections(this._registrations))
      
      return registrations?.data
    })
    .catch(err => {
        console.log('err', err)
        console.log('err status', err?.response?.status)
        return err;
    });
  };
  async updateRegistration(booking: any, cNumber: number): Promise<void> {
    console.log('is this not run again.. update Registrations')
    return await axios.put(`${this.apiUrlService.vUrl}customer/vorespant/vendor/${cNumber}/booking`, booking).then(registration => {
      // console.log('registrations', registrations)      
      console.log('registrations data', registration);
      
      return registration?.data
    })
    .catch(err => {
        console.log('err', err)
        console.log('err status', err?.response?.status)
        return err;
    });
  };
}
