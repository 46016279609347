import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { IonicModule } from '@ionic/angular';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  imports: [IonicModule],
  standalone: true,
})
export class LogoutComponent  implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit() {}

  public logout() {
    this.authService.setLogOut();
  }

}
