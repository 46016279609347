import { Injectable } from '@angular/core';
import axios from 'axios';
import { StorageService } from '../storage/storage.service';
import { ApiUrlService } from '../api-url/api-url.service';

@Injectable({
  providedIn: 'root'
})
export class TextStringsService {

  // private txt: any = {};

  constructor(
    public storage: StorageService, 
    private apiUrlService: ApiUrlService
    ) { }

  public getText(key: string) {
    return JSON.parse(localStorage.getItem(key))
  }
  

  async fetchTexts(): Promise<void> {
    console.log('running.. fetchTexts')
    return await axios.get(this.apiUrlService.cmsUrl+'wp-json/wp/v2/pages/34007').then(txt => {
      console.log('txts', txt)
      console.log('txts modded', txt?.data?.modified)
      // console.log('txts modded', txt?.data?.modified)
      
        // this.storage.get('txtLastModified').then((res: any) => {
          
          // if (res!==txt?.data?.modified) {
            // either not set or too old, doesnt matter, need updating
            // console.log('local mod val not equal server, updating cms texts...')
            for (const [key, value] of Object.entries(txt?.data?.acf)) {
              // console.log(`${key}: ${value}`)
              // this.storage.set(key, value)
              localStorage.setItem(key, JSON.stringify(value))
            }
            // set new truth og txtLastModified
            // this.storage.set('txtLastModified', txt?.data?.modified)
          // }
          // this.txt = txt?.data?.acf
          // console.log('this.txt', this.txt)
        // })
        // .catch((error: any) => {
        //   console.log('err getting txtLastModified', error)
        //   return error;
        // })


    })
    .catch(err => {
      // get existing text version from localstorage
        return err;
    });
  };
  
}
