// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// TODO move these api urls in plaintext from repo, go to pure proxy solution

declare const require: any;

export const environment = {
  production: false,
  apiUrl: 'https://apitest.danskretursystem.dk/KjQ4w42jjWeel3rf4QdeExgeWed87qQaK/3p/collection/api/rest',
  vUrl: 'https://dev-apivpant.danskretursystem.dk',
  cmsUrl: 'https://danskretursystem.dk',
  scope: 'openid https://drsidenttest.onmicrosoft.com/b055aefb-11c9-439b-8617-08efc5eec609/execute offline_access',
  oAuthTokenEndpoint: 'https://drsidenttest.b2clogin.com/drsidenttest.onmicrosoft.com/B2C_1A_VoresPantSignIn/oauth2/v2.0/token',
  oAuthAuthorizeEndpoint: 'https://drsidenttest.b2clogin.com/drsidenttest.onmicrosoft.com/B2C_1A_VoresPantSignIn/oauth2/v2.0/authorize',
  oAuthAppId: 'c41c1760-b0cc-4036-87b6-0aa13f730a2c',
  // apiUrl: 'https://api.danskretursystem.dk/WjQ4XF2jjQeel3344QdWTfqAed87qQaK/3p/collection/api/rest',
  appVersion: require('../../package.json').version,
};
// export const environment = {
//   production: false,
//   apiUrl: 'https://apitest.danskretursystem.dk/KjQ4w42jjWeel3rf4QdeExgeWed87qQaK/3p/collection/api/rest',
//   vUrl: 'https://apivpant.danskretursystem.dk',
//   cmsUrl: 'https://danskretursystem.dk',
//   oAuthTokenEndpoint: 'https://b2cdrstest.b2clogin.com/b2cdrstest.onmicrosoft.com/B2C_1A_VoresPantSignIn/oauth2/v2.0/token',
//   oAuthAuthorizeEndpoint: 'https://b2cdrstest.b2clogin.com/b2cdrstest.onmicrosoft.com/B2C_1A_VoresPantSignIn/oauth2/v2.0/authorize',
//   oAuthAppId: '41938d77-9b7d-42fb-a038-24ff333d5476',
//   // apiUrl: 'https://api.danskretursystem.dk/WjQ4XF2jjQeel3344QdWTfqAed87qQaK/3p/collection/api/rest',
//   appVersion: require('../../package.json').version,
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
