import { Injectable } from '@angular/core'; 
import { LoadingController } from '@ionic/angular'; 

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isLoading = false;
  constructor(
    public loadingController: LoadingController
  ) 
  { } 

  async loadingPresent() {
    console.log('vil gerne vise loader nu');
    this.isLoading = true;
    return await this.loadingController.create({
      message: 'Øjeblik ...',
      // duration: 3000,
      spinner: 'crescent'
    }).then(a => {
      a.present().then(() => {
        console.log('loading vist');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort loading'));
        }
      });
    });
  }

  async loadingDismiss() {
    setTimeout(() => {
      console.log('prøver nu at lukke loading, isLoading: ', this.isLoading)
      this.isLoading = false;
      return this.loadingController.dismiss().then(() => console.log('loading lukket'));
    }, 100);
    
  }
}